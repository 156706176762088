import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'

import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Slider from "react-slick"
import Img from "gatsby-image"
import img1 from "../../assets/img/restauration/img-1.png"
import img2 from "../../assets/img/restauration/img-2.png"
import img3 from "../../assets/img/restauration/img-3.jpg"
import img4 from "../../assets/img/restauration/img-4.png"
import cover from "../../assets/img/restauration/cover.png"
import catalogue from "../../assets/img/restauration/catalogue.jpg"
import catalogueFile from "../../assets/pdf/catalogue-restauration.pdf"
const RestaurationPage = ({ data }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  },
  [slider1, slider2]
  );
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.slider-nav'
  };
  const settingsThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '2px',
    arrows: false, 
  };
  const g = data.gallery.edges.map(({ node }) => node.childImageSharp)
 return(
  <Layout>
    <SEO title="Restauration" />
    <div class="w-full relative">
        <div className="slider-wrapper">
          <Slider  {...settingsMain}  asNavFor={nav2} ref={slider => (setSlider1(slider))}>
          { data.sld.edges.map(({ node, index }) =>
              <div className="slick-slide" key={index}>
                  <Img className="slick-slide-image" fluid={node.childImageSharp.full} />
              </div>
            )}
          </Slider>
        </div>
        <div className="thumbnail-slider-wrap absolute left-0 right-0 bottom-0 mx-auto w-1/4">
            <Slider {...settingsThumbs} asNavFor={nav1} ref={slider => (setSlider2(slider))}>
                { data.sld.edges.map(({ node, index }) =>
                  <div className="slick-slide slick-slide-thumb" key={index}>
                      <Img className="slick-slide-image rounded-full cursor-pointer" fluid={node.childImageSharp.thumb} />
                  </div>
                )}
            </Slider>
        </div>
    </div>
   <div class="grid grid-cols-1 mt-8 gap-5 md:mx-40 md:my-5 mx-3">
     <h1 class="text-center text-2xl font-bold uppercase">RESTAURATION</h1>
     <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
   </div>
   <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
       <img src={cover} alt="cover" />
    </div>
   <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
        <div>  
            <p class="mb-4">Dans tous les environnements de restauration, l’hygiène doit être optimale et non facultative.</p>

            <p class="mb-4">Le Solid Surface s’adapte à touts vos envies. Il peut servir à créer un bar, une chaise, un évier, une colonne, un plateau de table ou un plan de travail de cuisine.</p>

            <p class="my-6">Les applications  les plus connues pour ce fabuleux matériau sont notamment :</p>
            <div class="flex-col flex  md:gap-4">
                <p><span class="font-bold text-blue-700 mr-3">&#10003;</span> Plateaux de table</p>
                <p><span class="font-bold text-blue-700 mr-3">&#10003;</span>Comptoirs de bar</p>
                <p><span class="font-bold text-blue-700 mr-3">&#10003;</span>Espaces de préparation de nourriture</p>
                <p><span class="font-bold text-blue-700 mr-3">&#10003;</span>Comptoirs de meuble de salle de bain</p>
                <p><span class="font-bold text-blue-700 mr-3">&#10003;</span>Comptoirs de service/stations de boisson</p>
                <p><span class="font-bold text-blue-700 mr-3">&#10003;</span>Comptoirs de réception</p>
                <p><span class="font-bold text-blue-700 mr-3">&#10003;</span>Revêtement des murs et des comptoirs</p>
                <p><span class="font-bold text-blue-700 mr-3">&#10003;</span>Systèmes de cloison dans les toilettes </p>
            </div>
          </div>
       <div> 
         <img src={img1} alt="1" /> 
         <img src={img2} alt="2" /> 
      </div>
   </div>
   <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <div>
        <img src={img3} alt="3" />
        <p class="mb-3"><span class="font-bold text-blue-700 mr-3">&#10003;</span>Ne favorise pas le développement de moisissure quand il est nettoyé de manière appropriée.</p>
        <p class="mb-3"><span class="font-bold text-blue-700 mr-3">&#10003;</span>
          Résistant aux taches (les taches ne peuvent pas pénétrer et les décolorations mineures de la surface sont facilement réparées).
        </p>
         <p><span class="font-bold text-blue-700 mr-3">&#10003;</span>
            Disponible dans de nombreux coloris et dans des options personnalisées Le choix idéal pour la conception d’environnements de restauration
        </p>
      </div>
      <div class="col-span-1">
        <p class="font-bold text-xl text-center mb-3">Esthétique, et bien plus encore</p>
        <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
        <p class="mt-7 mb-4">Durable, facile à nettoyer et à entretenir, et renouvelable en cas d’éraflures.</p>

        <p class="my-4">Certifié selon la norme NSF/ANSI 51 pour le contact alimentaire en puisant dans une grande palette de couleurs, vous pouvez créer une ambiance et une expérience de la marque homogène, chaleureuse, attirante, dans de nombreuses zones.</p>
        <img src={img4} alt="4" />
      </div>
   </div>
    <div class="grid grid-cols-1 mt-8 gap-8 md:grid-cols-3 md:mx-40 md:my-5 mx-3 gl gl33">
       <div class="col-1 md:col-span-2"> <Gallery images={g} /></div>
       <div> 
          <a href={catalogueFile} class="relative block hover-trigger" download>
            <img src={catalogue} alt="cataloge restauration" class="my-0" />
            <div class="absolute left-0 right-0 text-center bottom-5 bg-white border border-grey-100 px-4 py-2 hover-target">
              Télécharger
            </div>
          </a>
      </div>
    </div>
   </Layout>  
)
}
export const query = graphql`
query RestautrationGallery {
  gallery:allFile (filter:{relativeDirectory: {eq: "img/restauration/g"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  sld:allFile (filter:{relativeDirectory: {eq: "img/restauration/s"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`
export default RestaurationPage